<div class="ui vertical stripe segment">
    <div class="ui text container">
      <h3 class="ui header">Founding</h3>
      <p>Founded in 2021 in Rocky Mountain House, Alberta, we exist to implement standard technologies into production environments to improve access to data, while lowering costs.</p>


      <h3 class="ui header">Experience</h3>
      <p>TriTAC Builds upon 50 Years of experience implementing automation solutions, and operating production facilities.</p>

    </div>
  </div>


