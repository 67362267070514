
  
<div class="ui inverted vertical center aligned segment">


      <div class="ui  secondary inverted pointing menu">
        <a class="toc item">
          <i (click)="toggleSide()" class="sidebar icon"></i>
        </a>
        <a class="item" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home</a>
        <a class="item" routerLink="/services" routerLinkActive="active">Services</a>
        <a class="item" routerLink="/about" routerLinkActive="active">About</a>
        <div class="right item">
          <a href="https://demo.tritac.ca" class="ui inverted button">Log in</a>
          <a class="item" href="https://newaccount1613263231154.freshdesk.com/support/home">Support</a>
        </div>
      </div>

</div> 
    
<!-- Sidebar Menu -->
<div (click)="toggleSide()" class="ui vertical inverted sidebar menu" [ngClass]="{'uncover': sideBar, 'visible': sideBar, 'left': sideBar}">
  <a class="item" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home</a>
  <a class="item" routerLink="/services" routerLinkActive="active">Services</a>
  <a class="item" routerLink="/about" routerLinkActive="active">About</a>
  <a class="item" href="https://newaccount1613263231154.freshdesk.com/support/home">Support</a>
</div>



<router-outlet></router-outlet>

<div class="ui inverted vertical footer segment">
  <div class="ui container">
    <div class="ui stackable inverted divided equal height stackable grid">
      
    </div>
  </div>
</div>