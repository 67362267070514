<div class="pusher">
    <div class="ui inverted vertical masthead center aligned segment">
        <div class="ui fluid image">
            <img src="/assets/frontbanner2.png">         
            <div class="imagetext">
                <h1 >
                    TriTAC Solutions
                  </h1>
                  <h2>Upstream Oil & Gas Data Management</h2>
                  <a class="ui huge primary button" routerLink="/services" >Explore Solutions <i class="right arrow icon"></i></a>
            </div>
              
      
          </div>
    </div>
    
    <div class="ui vertical stripe segment">
        <div class="ui middle aligned stackable grid container">
        <div class="row">
            <div class="eight wide column">
            <h3 class="ui header">Helping Upstream Producers Manage Data</h3>
            <p>We provide solutions to present field data to your web browser.</p>
            <h3 class="ui header">Bringing All Data Into One System</h3>
            <p>We use open standards and technologies, to move production data into a common system.</p>
            </div>
            <div class="six wide right floated column">
            
            </div>
        </div>
        <div class="row">
           
        </div>
        </div>
    </div>
</div>
  
