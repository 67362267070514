<div class="ui vertical stripe segment">
    <div class="ui text container">
      <h3 class="ui header">Networking</h3>
      <ul>
          <li>Licensed Frequency Management</li>
          <li>Routing Design and Configuration</li>
          <li>VPN Design and Configuration</li>
      </ul>
      <h3 class="ui header">Software Development</h3>
      <ul>
          <li>Full Stack Web Development - Utilising custom or prebuilt solutions.</li>
          <li>Custom RTU Driver Development</li>
          <li>AGA Flow Calculations</li>
          <li>GIS Product Development</li>
      </ul>

    </div>
  </div>


